import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import routes from "./routes";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.onError((error) => {
  if (error.name === "ChunkLoadError" && window.location.hash !== "#retry") {
    // the chunk might no longer be available due to a recent redeployment of the page
    // mark the page to don't trigger reload infinitely
    window.location.hash = "#retry";
    window.location.reload();
  }
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  // If auth isn't required for the route, just continue.
  if (!authRequired) {
    return next();
  }

  if (!store.getters.stateUser) {
    // Load user data if it is not loaded
    await store.dispatch("loadUser");
  }

  // If auth is required and the user is logged in...
  if (store.getters.stateUser) {
    // If a user tries to access to a route that does not belong to him, redirect him to its home page
    if (
      routeTo.meta.userTypes &&
      !routeTo.meta.userTypes.includes(store.getters?.userType)
    ) {
      const nextRoute = store.getters.homeRoute;
      nextRoute.query = { ...nextRoute.query, redirectFrom: routeTo.fullPath };
      return next(nextRoute);
    }

    return next();
  } else {
    next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              // @ts-ignore
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          // @ts-ignore
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

export default router;

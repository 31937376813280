import router from "@/plugins/router";
import { authApi } from "@/services/api/AuthApi";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
  state as authState,
  getters as authGetters,
  mutations as authMutations,
  actions as authActions,
} from "./authStore";
import {
  state as errorState,
  getters as errorGetters,
  mutations as errorMutations,
  actions as errorActions,
} from "./notificationStore";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ...authState,
    ...errorState,
  },
  mutations: {
    ...authMutations,
    ...errorMutations,
  },
  getters: {
    ...authGetters,
    ...errorGetters,
  },
  actions: {
    ...authActions,
    ...errorActions,
  },
  plugins: [createPersistedState()],
});


import { mapState } from "vuex";
export default {
  props: {
    dense: Boolean,
    prominent: Boolean,
  },
  name: "AlertComponent",
  methods: {
    close(i) {
      this.$store.commit("REMOVE_NOTIFICATION", i);
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    ...mapState(["notifications"]),
  },
  watch: {
    notifications() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.$store.commit("FLUSH_NOTIFICATIONS");
      }, 30000);
    },
  },
};

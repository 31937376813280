import { RouteConfig } from "vue-router";
import store from "@/store";

export const authRoutes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "showcase" */ "../../../views/LoginView.vue"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters.isAuthenticated) {
          // Redirect to the home page instead
          return next(store.getters.homeRoute);
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
];

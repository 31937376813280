import VueI18n from "vue-i18n";
import Vue from "vue";
import en from "@/locales/en.json";
import it from "@/locales/it.json";
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang"), // set locale
  fallbackLocale: "",
  fallbackRootWithEmptyString: true,
  messages: {
    en,
    it,
  }, // set locale messages
});

import router from "@/plugins/router";
import store from "@/store";
import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    ContentType: "application/json",
    "X-User-Language": localStorage.getItem("lang"),
  },
});

instance.defaults.withCredentials = true;
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch("logout");
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      store.commit("NOTIFY_ERROR", {
        errors: error.response.data.errors,
      });
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      store.commit("NOTIFY_ERROR", [error.response.data.message]);
    }
    return Promise.reject(error);
  }
);

export const api = instance;

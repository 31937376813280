import { nutritionistsRoutes } from "@/plugins/router/routes/nutritionists";
import { structuresRoutes } from "@/plugins/router/routes/structures";
import { authRoutes } from "@/plugins/router/routes/auth";
import { userGroupsRoutes } from "@/plugins/router/routes/userGroups";
import { evaluationsRoutes } from "@/plugins/router/routes/evaluations";
import { patientsRoutes } from "@/plugins/router/routes/patients";
import { activityRoutes } from "@/plugins/router/routes/activities";
import { userRoutes } from "@/plugins/router/routes/users";
import { reportsRoutes } from "@/plugins/router/routes/reports";
import { alarmNoificationsRoutes } from "@/plugins/router/routes/alarmNotifications";

export default [
  ...nutritionistsRoutes,
  ...structuresRoutes,
  ...authRoutes,
  ...userGroupsRoutes,
  ...evaluationsRoutes,
  ...patientsRoutes,
  ...activityRoutes,
  ...userRoutes,
  ...reportsRoutes,
  ...alarmNoificationsRoutes,
  {
    path: "*",
    redirect: "/login",
  },
];

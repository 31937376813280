<template>
  <v-menu offset-y>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        color="primary elevation-0"
        v-bind="attrs"
        v-on="on"
        class="ma-0 pa-0"
      >
        <span v-if="$vuetify.breakpoint.smAndUp" class="text-none">
          {{ stateUser().name }}
        </span>
        <v-avatar>
          <v-icon color="white" size="30"> mdi-account-circle </v-icon>
        </v-avatar>
      </v-btn>
    </template>

    <v-list>
      <v-list-item :selectable="false">
        <v-list-item-content>
          <v-list-item-title>
            {{ stateUser().name }}
            <span v-if="structure">| {{ structure }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
      <v-list-item @click="goToProfile">
        <v-list-item-content class="text_white">
          <v-list-item-title>{{ $t("userMenu.profile") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logoutClick">
        <v-list-item-content class="text_white">
          <v-list-item-title>{{ $t("userMenu.logout") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserMenu",
  data() {
    return {
      language: "en",
    };
  },
  computed: {
    structure() {
      if (this.stateUser().admin) {
        return this.stateUser().admin.structure?.name;
      } else if (this.stateUser().employee) {
        return this.stateUser().employee.structure?.name;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapGetters(["stateUser"]),
    goToProfile() {
      this.$router.push({
        name: "profile",
      });
    },
    logoutClick() {
      this.logout();
    },
  },
};
</script>

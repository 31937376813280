import { Route, RouteConfig } from "vue-router";

export const nutritionistsRoutes: Array<RouteConfig> = [
  {
    path: "/nutritionists",
    name: "nutritionists",
    meta: {
      breadcrumb: [
        {
          text: "Nutritionists",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/NutritionistsView.vue"
      ),
  },
  {
    path: "/nutritionists/add",
    name: "nutritionistAdd",
    meta: {
      breadcrumb(route: Route) {
        return [
          {
            text: "Nutritionists",
            to: { name: "nutritionists" },
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddNutritionistView.vue"
      ),
  },
  {
    path: "/nutritionists/:id/edit",
    name: "nutritionistsEdit",
    meta: {
      breadcrumb(route: Route) {
        const nutritionistName = route.query.nutritionistName;
        return [
          {
            text: "Nutritionists",
            to: { name: "nutritionists" },
          },
          {
            text: nutritionistName,
          },
          {
            text: "Edit",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddNutritionistView.vue"
      ),
  },
  {
    path: "/nutritionists/:id",
    name: "nutritionistsDetails",
    meta: {
      breadcrumb(route: Route) {
        const nutritionistName = route.query.nutritionistName;
        return [
          {
            text: "Nutritionists",
            to: { name: "nutritionists" },
          },
          {
            text: nutritionistName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SingleNutritionistView.vue"
      ),
  },
];

for (const nutritionistsRoute of nutritionistsRoutes) {
  if (!nutritionistsRoute.meta) {
    nutritionistsRoute.meta = {};
  }
  nutritionistsRoute.meta.authRequired = true;
  nutritionistsRoute.meta.userTypes = ["super_admin"];
}

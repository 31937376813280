import { Route, RouteConfig } from "vue-router";

export const structuresRoutes: Array<RouteConfig> = [
  {
    path: "/structures",
    name: "structures",
    meta: {
      breadcrumb: [
        {
          text: "Structures",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/StructuresView.vue"
      ),
  },
  {
    path: "/structures/:id",
    name: "singleStructure",
    meta: {
      userTypes: ["admin"],
      breadcrumb(route: Route) {
        const structureName = route.query.structureName;
        return [
          {
            text: "Structures",
            to: { name: "structures" },
          },
          {
            text: structureName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SingleStructureView.vue"
      ),
  },
  {
    path: "/structures/:structureId/responsibilities/:evaluationId/edit",
    name: "structureResponsibilitiesEdit",
    meta: {
      userTypes: ["admin"],
      breadcrumb(route: Route) {
        const structureName = route.query.structureName;
        const evaluationName = route.query.evaluationName;
        const structureId = route.params.id;
        return [
          {
            text: "Structures",
            to: { name: "structures" },
          },
          {
            text: structureName,
            to: {
              name: "singleStructure",
              params: { structureId },
              query: { structureName },
            },
          },
          {
            text: "Responsibilities",
          },
          {
            text: evaluationName,
          },
          {
            text: "Edit",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/EditStructureResponsibilitiesView.vue"
      ),
  },
  {
    path: "/structures/:structureId/employees/add",
    name: "structureEmployeesAdd",
    meta: {
      userTypes: ["admin"],
      breadcrumb(route: Route) {
        const structureName = route.query.structureName;
        const structureId = route.params.structureId;
        return [
          {
            text: "Structures",
            to: { name: "structures" },
          },
          {
            text: structureName,
            to: {
              name: "singleStructure",
              params: { id: structureId },
              query: { structureName },
            },
          },
          {
            text: "Employees",
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddStructureEmployeeView.vue"
      ),
  },
  {
    path: "/structures/:structureId/employees/:employeeId/:edit",
    name: "structureEmployeesEdit",
    meta: {
      userTypes: ["admin"],
      breadcrumb(route: Route) {
        const structureName = route.query.structureName;
        const structureId = route.params.structureId;
        const employeeName = route.params.employeeName;
        return [
          {
            text: "Structures",
            to: { name: "structures" },
          },
          {
            text: structureName,
            to: {
              name: "singleStructure",
              params: { id: structureId },
              query: { structureName },
            },
          },
          {
            text: "Employees",
          },
          {
            text: employeeName,
          },
          {
            text: "Edit",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddStructureEmployeeView.vue"
      ),
  },
  {
    path: "/structures/:structureId/administrators/add",
    name: "structureAdministratorsAdd",
    meta: {
      userTypes: ["admin"],
      breadcrumb(route: Route) {
        const structureName = route.query.structureName;
        const structureId = route.params.structureId;
        return [
          {
            text: "Structures",
            to: { name: "structures" },
          },
          {
            text: structureName,
            to: {
              name: "singleStructure",
              params: { id: structureId },
              query: { structureName },
            },
          },
          {
            text: "Administrators",
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddStructureAdministratorView.vue"
      ),
  },
  {
    path: "/structures/:structureId/administrators/:administratorId/:edit",
    name: "structureAdministratorsEdit",
    meta: {
      userTypes: ["admin"],
      breadcrumb(route: Route) {
        const structureName = route.query.structureName;
        const structureId = route.params.structureId;
        const administratorName = route.params.administratorName;
        return [
          {
            text: "Structures",
            to: { name: "structures" },
          },
          {
            text: structureName,
            to: {
              name: "singleStructure",
              params: { id: structureId },
              query: { structureName },
            },
          },
          {
            text: "Administrators",
          },
          {
            text: administratorName,
          },
          {
            text: "Edit",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddStructureAdministratorView.vue"
      ),
  },
  {
    path: "/structures/:structureId/employees/:employeeId",
    name: "structureEmployeeDetails",
    meta: {
      userTypes: ["admin"],
      breadcrumb(route: Route) {
        const structureName = route.query.structureName;
        const structureId = route.params.structureId;
        const employeeName = route.query.employeeName;
        return [
          {
            text: "Structures",
            to: { name: "structures" },
          },
          {
            text: structureName,
            to: {
              name: "singleStructure",
              params: { id: structureId },
              query: { structureName },
            },
          },
          {
            text: "Employees",
          },
          {
            text: employeeName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SingleEmployeeView.vue"
      ),
  },
  {
    path: "/structures/:structureId/administrators/:administratorId",
    name: "structureAdministratorDetails",
    meta: {
      userTypes: ["admin"],
      breadcrumb(route: Route) {
        const structureName = route.query.structureName;
        const structureId = route.params.structureId;
        const administratorName = route.query.administratorName;
        return [
          {
            text: "Structures",
            to: { name: "structures" },
          },
          {
            text: structureName,
            to: {
              name: "singleStructure",
              params: { id: structureId },
              query: { structureName },
            },
          },
          {
            text: "Administrators",
          },
          {
            text: administratorName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SingleAdministratorView.vue"
      ),
  },
];

for (const structuresRoute of structuresRoutes) {
  if (!structuresRoute.meta) {
    structuresRoute.meta = {};
  }
  structuresRoute.meta.authRequired = true;
  structuresRoute.meta.userTypes
    ? structuresRoute.meta.userTypes.push("super_admin")
    : (structuresRoute.meta.userTypes = ["super_admin"]);
}

import { Route, RouteConfig } from "vue-router";

export const patientsRoutes: Array<RouteConfig> = [
  {
    path: "/patients",
    name: "patients",
    meta: {
      breadcrumb: [
        {
          text: "Patients",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/PatientsView.vue"
      ),
  },
  {
    path: "/patients/:id",
    name: "singlePatient",
    meta: {
      breadcrumb(route: Route) {
        const patientName = route.query.patientName;
        return [
          {
            text: "Patients",
            to: { name: "patients" },
          },
          {
            text: patientName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SinglePatientView.vue"
      ),
  },
  {
    path: "/patients/:id/documents/add",
    name: "addDocument",
    meta: {
      breadcrumb(route: Route) {
        const patientName = route.query.patientName;
        const patientId = route.params.id;
        return [
          {
            text: "Patients",
            to: { name: "patients" },
          },
          {
            text: patientName,
            to: {
              name: "singlePatient",
              params: {
                id: patientId,
              },
              query: {
                patientName,
              },
            },
          },
          {
            text: "Documents",
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddDocumentView.vue"
      ),
  },
  {
    path: "/patients/:id/documents/:documentId/edit",
    name: "documentEdit",
    meta: {
      breadcrumb(route: Route) {
        const patientName = route.query.patientName;
        const patientId = route.params.id;
        const documentTitle = route.query.documentTitle;
        return [
          {
            text: "Patients",
            to: { name: "patients" },
          },
          {
            text: patientName,
            to: {
              name: "singlePatient",
              params: {
                id: patientId,
              },
              query: {
                patientName,
              },
            },
          },
          {
            text: "Documents",
          },
          {
            text: documentTitle,
          },
          {
            text: "Edit",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddDocumentView.vue"
      ),
  },
  {
    path: "/patients/:id/activityPlan/add",
    name: "addActivityPlan",
    meta: {
      breadcrumb(route: Route) {
        const patientName = route.query.patientName;
        const patientId = route.params.id;
        return [
          {
            text: "Patients",
            to: { name: "patients" },
          },
          {
            text: patientName,
            to: {
              name: "singlePatient",
              params: {
                id: patientId,
              },
              query: {
                patientName,
              },
            },
          },
          {
            text: "ActivityPlan",
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddActivityPlanView.vue"
      ),
  },
  {
    path: "/patients/:id/activityPlan/:activityPlanId/edit",
    name: "activityPlanEdit",
    meta: {
      breadcrumb(route: Route) {
        const patientName = route.query.patientName;
        const patientId = route.params.id;
        const activityPlanName = route.query.activityPlanName;
        return [
          {
            text: "Patients",
            to: { name: "patients" },
          },
          {
            text: patientName,
            to: {
              name: "singlePatient",
              params: {
                id: patientId,
              },
              query: {
                patientName,
              },
            },
          },
          {
            text: "ActivityPlan",
          },
          {
            text: activityPlanName,
          },
          {
            text: "Edit",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddActivityPlanView.vue"
      ),
  },
  {
    path: "/patients/:id/activities/:activityId/evaluation",
    name: "addPatientActivityEvaluation",
    meta: {
      breadcrumb(route: Route) {
        const patientName = route.query.patientName;
        const patientId = route.params.id;
        const evaluationName = route.query.evaluationName;

        return [
          {
            text: "Patients",
            to: { name: "patients" },
          },
          {
            text: patientName,
            to: {
              name: "singlePatient",
              params: {
                id: patientId,
              },
              query: {
                patientName,
              },
            },
          },
          {
            text: evaluationName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SubmitEvaluationView.vue"
      ),
  },
  {
    path: "/patients/:id/activities/add",
    name: "addPatientActivity",
    meta: {
      breadcrumb(route: Route) {
        const patientName = route.query.patientName;
        const patientId = route.params.id;

        return [
          {
            text: "Patients",
            to: { name: "patients" },
          },
          {
            text: patientName,
            to: {
              name: "singlePatient",
              params: {
                id: patientId,
              },
              query: {
                patientName,
              },
            },
          },
          {
            text: "Activities",
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddPatientActivityView.vue"
      ),
  },
];

for (const patientsRoute of patientsRoutes) {
  if (!patientsRoute.meta) {
    patientsRoute.meta = {};
  }
  patientsRoute.meta.authRequired = true;
  patientsRoute.meta.userTypes = ["employee", "nutritionist"];
}

import { Route, RouteConfig } from "vue-router";

export const alarmNoificationsRoutes: Array<RouteConfig> = [
  {
    path: "/alarm-notifications",
    name: "alarmNotifications",
    meta: {
      breadcrumb: [
        {
          text: "Notifications",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AlarmNotificationsView.vue"
      ),
  },
];

for (const alarmNotificationsRoute of alarmNoificationsRoutes) {
  if (!alarmNotificationsRoute.meta) {
    alarmNotificationsRoute.meta = {};
  }
  alarmNotificationsRoute.meta.authRequired = true;
  alarmNotificationsRoute.meta.userTypes = ["employee", "nutritionist"];
}

<template>
  <v-breadcrumbs :items="breadcrumbs" divider=">" class="pb-0">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        class="text-subtitle-2 crumb-item"
        :disabled="item.disabled"
        exact
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "BreadcrumbComponent",
  computed: {
    breadcrumbs() {
      if (typeof this.$route.meta.breadcrumb === "function") {
        return this.$route.meta.breadcrumb.call(this, this.$route);
      }
      return this.$route.meta.breadcrumb;
    },
  },
};
</script>

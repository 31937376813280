import { RouteConfig } from "vue-router";

export const userRoutes: Array<RouteConfig> = [
  {
    path: "/profile",
    name: "profile",
    meta: {
      breadcrumb: [
        {
          text: "Profile",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SingleUserView.vue"
      ),
  },
];

for (const userRoute of userRoutes) {
  if (!userRoute.meta) {
    userRoute.meta = {};
  }
  userRoute.meta.authRequired = true;
}

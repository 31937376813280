<template>
  <v-menu offset-y>
    <template v-slot:activator="{ attrs, on }">
      <v-btn v-bind="attrs" v-on="on" text class="ma-0 pa-0">
        <img :src="getIcon" width="24" height="24" alt="" />
        <v-icon :color="black ? 'black' : 'white'"> mdi-menu-down </v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        @click="() => select(l)"
        v-for="l in languages"
        :key="l.value"
        link
      >
        <v-list-item-title> {{ l.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import en from "../../assets/icons/united-kingdom.svg";
import it from "../../assets/icons/italy.svg";
import { ApiUtils } from "@/services/api/ApiUtils";

export default {
  name: "LanguagePicker",
  data() {
    return {
      language: "en",
      languages: [
        {
          value: "en",
          text: this.$t("common.english"),
        },
        {
          value: "it",
          text: this.$t("common.italian"),
        },
      ],
    };
  },
  mounted() {
    const initialLanguage = localStorage.getItem("lang");
    if (initialLanguage) {
      this.language = initialLanguage;
    }
  },
  props: {
    black: Boolean,
  },
  methods: {
    select(l) {
      this.language = l.value;
      localStorage.setItem("lang", l.value);
      ApiUtils.addDefaultHeader("X-User-Language", l.value);
      this.$root.$i18n.locale = l.value;
      location.reload();
    },
  },
  computed: {
    getIcon() {
      switch (this.language) {
        case "en":
          return en;
        case "it":
          return it;
        default:
          throw new Error("Undefined language");
      }
    },
  },
};
</script>

import { Route, RouteConfig } from "vue-router";

export const reportsRoutes: Array<RouteConfig> = [
  {
    path: "/reports",
    name: "reports",
    meta: {
      breadcrumb: [
        {
          text: "Reports",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/ReportsView.vue"
      ),
  },
  {
    path: "/reports/generate",
    name: "generateReport",
    meta: {
      breadcrumb: [
        {
          text: "Reports",
          to: { name: "reports" },
        },
        {
          text: "Generate",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddReportView.vue"
      ),
  },
  {
    path: "/reports/:id",
    name: "singleReportConfigurations",
    meta: {
      breadcrumb(route: Route) {
        const name = route.query.name;
        return [
          {
            text: "Reports",
            to: { name: "reports" },
          },
          {
            text: "Configurations",
          },
          {
            text: name,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/ReportConfigurationView.vue"
      ),
  },
  {
    path: "/reports/configurations/add",
    name: "addReport",
    meta: {
      breadcrumb: [
        {
          text: "Reports",
          to: { name: "reports" },
        },
        {
          text: "Configurations",
        },
        {
          text: "Add",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/ReportConfigurationView.vue"
      ),
  },
];

for (const reportsRoute of reportsRoutes) {
  if (!reportsRoute.meta) {
    reportsRoute.meta = {};
  }
  reportsRoute.meta.authRequired = true;
  reportsRoute.meta.userTypes = [
    "employee",
    "nutritionist",
    "admin",
    "super_admin",
  ];
}

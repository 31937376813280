import { Route, RouteConfig } from "vue-router";

export const activityRoutes: Array<RouteConfig> = [
  {
    path: "/activities",
    name: "activities",
    meta: {
      breadcrumb: [
        {
          text: "Activities",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/ActivitiesView.vue"
      ),
  },
  {
    path: "/activities/:activityId/evaluation",
    name: "addActivityEvaluation",
    meta: {
      breadcrumb(route: Route) {
        const patientName = route.query.patientName;
        const patientId = route.params.id;
        const evaluationName = route.query.evaluationName;

        return [
          {
            text: "Activities",
            to: { name: "activities" },
          },
          {
            text: patientName,
            to: {
              name: "singlePatient",
              params: {
                id: patientId,
              },
              query: {
                patientName,
              },
            },
          },
          {
            text: evaluationName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SubmitEvaluationView.vue"
      ),
  },
  {
    path: "/activities/add",
    name: "addActivity",
    meta: {
      breadcrumb(route: Route) {
        return [
          {
            text: "Activities",
            to: route.redirectedFrom,
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddActivityView.vue"
      ),
  },
];

for (const activityRoute of activityRoutes) {
  if (!activityRoute.meta) {
    activityRoute.meta = {};
  }
  activityRoute.meta.authRequired = true;
  activityRoute.meta.userTypes = ["employee", "nutritionist"];
}

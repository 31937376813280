export const state = {
  notifications: [],
};

export const getters = {
  notifications(state) {
    return state.notifications;
  },
};

export const mutations = {
  REMOVE_NOTIFICATION(state, i) {
    state.notifications.splice(i, 1);
  },
  FLUSH_NOTIFICATIONS(state) {
    state.notifications = [];
  },
  NOTIFY_SUCCESS(state, notifications = []) {
    state.notifications.push(
      ...notifications.map((n) => {
        return {
          message: n,
          type: "success",
        };
      })
    );
  },
  NOTIFY_INFO(state, notifications) {
    state.notifications.push(
      ...notifications.map((n) => {
        return {
          message: n,
          type: "info",
        };
      })
    );
  },
  NOTIFY_ERROR(state, notifications) {
    if (notifications.errors) {
      Object.keys(notifications.errors).forEach((k) => {
        state.notifications.push(
          ...notifications.errors[k].map((m) => {
            return {
              message: m,
              type: "error",
            };
          })
        );
      });
      return;
    }
    state.notifications.push(
      ...notifications.map((n) => {
        return {
          message: n,
          type: "error",
        };
      })
    );
  },
};

export const actions = {};

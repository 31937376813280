
import TextComponent from "./TextComponent.vue";
import LanguagePicker from "./LanguagePicker.vue";
import UserMenu from "./UserMenu.vue";
import AlertComponent from "@/components/core/AlertComponent.vue";

interface NavItem {
  icon: String;
  title: String;
  link: String;
  userTypes: [];
}

export default {
  components: { AlertComponent, TextComponent, LanguagePicker, UserMenu },
  name: "NavigationDrawer",
  props: {
    items: Array as () => NavItem[],
  },
  data() {
    return {
      active: null as NavItem,
      drawerOpen: false,
    };
  },
  computed: {
    drawer: {
      get() {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return true;
        } else {
          return this.drawerOpen;
        }
      },
      set(val) {
        if (!this.$vuetify.breakpoint.mdAndUp) {
          this.drawerOpen = val;
        }
      },
    },
  },
};

import { Route, RouteConfig } from "vue-router";

export const userGroupsRoutes: Array<RouteConfig> = [
  {
    path: "/user-groups",
    name: "user-groups",
    meta: {
      breadcrumb: [
        {
          text: "User Groups",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/UserGroupsView.vue"
      ),
  },
  {
    path: "/user-groups/:id",
    name: "singleUserGroup",
    meta: {
      breadcrumb(route: Route) {
        const userGroupName = route.query.userGroupName;
        return [
          {
            text: "User Groups",
            to: { name: "user-groups" },
          },
          {
            text: userGroupName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SingleUserGroupView.vue"
      ),
  },
  {
    path: "/user-groups/:id/responsibilities/add",
    name: "userGroupResponsibilitiesAdd",
    meta: {
      breadcrumb(route: Route) {
        const userGroupName = route.query.userGroupName;
        const userGroupId = route.params.id;
        return [
          {
            text: "User Groups",
            to: { name: "user-groups" },
          },
          {
            text: userGroupName,
            to: {
              name: "singleUserGroup",
              params: { userGroupId },
              query: { userGroupName },
            },
          },
          {
            text: "Responsibilities",
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddUserGroupResponsibilitiesView.vue"
      ),
  },
  {
    path: "/user-groups/:id/policies/add",
    name: "userGroupPoliciesAdd",
    meta: {
      breadcrumb(route: Route) {
        const userGroupName = route.query.userGroupName;
        const userGroupId = route.params.id;
        return [
          {
            text: "User Groups",
            to: { name: "user-groups" },
          },
          {
            text: userGroupName,
            to: {
              name: "singleUserGroup",
              params: { userGroupId },
              query: { userGroupName },
            },
          },
          {
            text: "Permissions",
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddUserGroupPoliciesView.vue"
      ),
  },
  {
    path: "/user-groups/:userGroupId/employees/:employeeId/add",
    name: "userGroupEmployeeDetails",
    meta: {
      breadcrumb(route: Route) {
        const userGroupName = route.query.userGroupName;
        const userGroupId = route.params.userGroupId;
        const employeeName = route.query.employeeName;

        return [
          {
            text: "User Groups",
            to: { name: "user-groups" },
          },
          {
            text: userGroupName,
            to: {
              name: "singleUserGroup",
              params: { id: userGroupId },
              query: { userGroupName },
            },
          },
          {
            text: "Employees",
          },
          {
            text: employeeName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SingleEmployeeView.vue"
      ),
  },
];

for (const userGroupsRoute of userGroupsRoutes) {
  if (!userGroupsRoute.meta) {
    userGroupsRoute.meta = {};
  }
  userGroupsRoute.meta.authRequired = true;
  userGroupsRoute.meta.userTypes = ["super_admin"];
}

import { AxiosResponse } from "axios";
import { api } from "./Api";

const login = async (request): Promise<AxiosResponse> => {
  return api.post("/login", request);
};

const logout = async (): Promise<AxiosResponse> => {
  return api.post("/logout");
};

const user = async (): Promise<AxiosResponse> => {
  return api.get("/api/user");
};

const resetPassword = async (payload): Promise<AxiosResponse> => {
  return api.put("/user/password", payload);
};

const adminResetPassword = async (id, payload): Promise<AxiosResponse> => {
  return api.post(`admin/user/${id}/password`, payload);
};

export const authApi = {
  login,
  logout,
  user,
  resetPassword,
  adminResetPassword,
};

import router from "@/plugins/router";
import { authApi } from "@/services/api/AuthApi";

export const state = {
  user: null,
};
export const mutations = {
  setUser(state, username) {
    state.user = username;
  },
};

export const getters = {
  isAuthenticated: (state) => !!state.user,
  stateUser: (state) => {
    return state.user;
  },
  userType: (state) => {
    return state.user?.type;
  },
  homeRoute: (state, getters) => {
    switch (getters.userType) {
      case "super_admin":
        return { name: "structures" };
      case "admin":
        return {
          name: "singleStructure",
          params: {
            id: getters.stateUser.admin?.structure_id,
          },
        };
      case "employee":
      case "nutritionist":
        return { name: "alarmNotifications" };
    }
  },
};

export const actions = {
  async login({ commit, dispatch, getters }, loginRequest) {
    const response = await authApi.login(loginRequest);
    if (response && response.status === 200) {
      commit("FLUSH_NOTIFICATIONS");
      await dispatch("loadUser");
      await router.push(getters.homeRoute);
    }
  },
  async loadUser({ commit }) {
    const user = (await authApi.user()).data;
    commit("setUser", user);
  },
  async logout({ commit }) {
    commit("FLUSH_NOTIFICATIONS");
    commit("setUser", null);
    await authApi.logout();
    await router.push({ name: "login" });
  },
};

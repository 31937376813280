import store from "@/store";

export const Can = {
  inserted(el, binding, vnode) {
    let availableForUsers = binding.value;
    if (!Array.isArray(availableForUsers)) {
      availableForUsers = availableForUsers.split(",");
    }

    if (availableForUsers.includes(store.getters.userType)) {
      return;
    }

    if (binding.modifiers["remove-otherwise"]) {
      if (vnode.elm.parentElement) {
        vnode.elm.parentElement.removeChild(vnode.elm);
      } else {
        el.style.display = "none";
      }
    } else if (binding.modifiers["hide-otherwise"]) {
      el.style.display = "none";
    } else if (binding.modifiers["disabled-otherwise"]) {
      el.classList.add("disabled");
    } else {
      el.style.display = "none";
    }
  },
};

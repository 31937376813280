import { Route, RouteConfig } from "vue-router";

export const evaluationsRoutes: Array<RouteConfig> = [
  {
    path: "/evaluations",
    name: "evaluations",
    meta: {
      breadcrumb: [
        {
          text: "Evaluations",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/EvaluationsView.vue"
      ),
  },
  {
    path: "/evaluations/add",
    name: "evaluationsAdd",
    meta: {
      breadcrumb(route: Route) {
        return [
          {
            text: "Evaluations",
            to: { name: "evaluations" },
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddEvaluationsView.vue"
      ),
  },
  {
    path: "/evaluations/:id",
    name: "singleEvaluation",
    meta: {
      breadcrumb(route: Route) {
        const evaluationName = route.query.evaluationName;
        return [
          {
            text: "Evaluations",
            to: { name: "evaluations" },
          },
          {
            text: evaluationName,
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/SingleEvaluationView.vue"
      ),
  },
  {
    path: "/evaluations/:evaluationId/alarms/add",
    name: "addEvaluationAlarm",
    meta: {
      breadcrumb(route: Route) {
        const evaluationName = route.query.evaluationName;
        return [
          {
            text: "Evaluations",
            to: { name: "evaluations" },
          },
          {
            text: evaluationName,
            to: {
              name: "singleEvaluation",
              params: {
                id: route.params.evaluationId,
              },
              query: {
                evaluationName,
              },
            },
          },
          {
            text: "Alarms",
          },
          {
            text: "Add",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddEvaluationAlarmView.vue"
      ),
  },
  {
    path: "/evaluations/:evaluationId/alarms/:id/edit",
    name: "editEvaluationAlarm",
    meta: {
      breadcrumb(route: Route) {
        const evaluationName = route.query.evaluationName;
        const alarmName = route.query.alarmName;
        return [
          {
            text: "Evaluations",
            to: { name: "evaluations" },
          },
          {
            text: evaluationName,
            to: {
              name: "singleEvaluation",
              params: {
                id: route.params.evaluationId,
              },
              query: {
                evaluationName,
              },
            },
          },
          {
            text: "Alarms",
          },
          {
            text: alarmName,
          },
          {
            text: "Edit",
          },
        ];
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "showcase" */ "../../../views/AddEvaluationAlarmView.vue"
      ),
  },
];

for (const evaluationsRoute of evaluationsRoutes) {
  if (!evaluationsRoute.meta) {
    evaluationsRoute.meta = {};
  }
  evaluationsRoute.meta.authRequired = true;
  evaluationsRoute.meta.userTypes = ["super_admin"];
}

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./plugins/router";
import Vuelidate from "vuelidate";
import { i18n } from "@/plugins/i18n";
import { Can } from "@/directives/can";

Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.directive("can", Can);

new Vue({
  i18n,
  vuetify,
  store: store,
  router,
  render: (h) => h(App),
}).$mount("#app");


import NavigationDrawer from "./components/core/NavigationDrawer.vue";
import BreadcrumbComponent from "@/components/core/BreadcrumbComponent.vue";

export default {
  name: "DashboardView",
  data() {
    return {
      items: [
        {
          title: this.$t("sidebar.structures"),
          icon: "mdi-domain",
          link: "/structures",
          userTypes: ["admin", "super_admin"],
        },
        {
          title: this.$t("sidebar.userGroups"),
          icon: "mdi-account-multiple",
          link: "/user-groups",
          userTypes: ["super_admin"],
        },
        {
          title: this.$t("sidebar.nutritionists"),
          icon: "mdi-food",
          link: "/nutritionists",
          userTypes: ["super_admin"],
        },
        {
          title: this.$t("sidebar.evaluations"),
          icon: "mdi-test-tube-empty",
          link: "/evaluations",
          userTypes: ["super_admin"],
        },
        {
          title: this.$t("sidebar.notifications"),
          icon: "mdi-card-account-details-outline",
          link: "/alarm-notifications",
          userTypes: ["employee", "nutritionist"],
        },
        {
          title: this.$t("sidebar.tasks"),
          icon: "mdi-card-account-details-outline",
          link: "/activities",
          userTypes: ["employee", "nutritionist"],
        },
        {
          title: this.$t("sidebar.patients"),
          icon: "mdi-card-account-details-outline",
          link: "/patients",
          userTypes: ["employee", "nutritionist"],
        },
        {
          title: this.$t("sidebar.reports"),
          icon: "mdi-card-account-details-outline",
          link: "/reports",
          userTypes: ["employee", "nutritionist", "super_admin", "admin"],
        },
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    userType() {
      return this.$store.getters.userType;
    },
  },
  components: {
    BreadcrumbComponent,
    NavigationDrawer,
  },
};
